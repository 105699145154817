import { createRouter, createWebHistory } from "vue-router"

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/out",
            component: () => import("@/src/components/OutPage.vue"),
            children: [
                {
                    path: "/login",
                    component: () => import("@/src/components/LoginForm.vue"),
                    name: "login",
                },
                {
                    path: "/users/new",
                    component: () => import("@/src/components/NewUserForm.vue"),
                    name: "new_user",
                },
            ],
        },
        {
            path: "/in",
            component: () => import("@/src/components/InPage.vue"),
            meta: { requiresAuth: true },
            children: [
                {
                    path: "/dashboard",
                    component: () => import("@/src/components/DashboardPage.vue"),
                    name: "dashboard",
                },
                {
                    path: "/user",
                    component: () => import("@/src/components/UserForm.vue"),
                    name: "user_edit",
                },
                {
                    path: "/uploads",
                    component: () => import("@/src/components/UploadPage.vue"),
                    name: "upload",
                },
                {
                    path: "/api_keys",
                    component: () => import("@/src/components/ApiKeyPage.vue"),
                    name: "api_keys",
                },
                {
                    path: "/upload_types",
                    component: () => import("@/src/components/UploadTypePage.vue"),
                    name: "upload_types",
                },
            ],
        },
    ],
});

export default router;
