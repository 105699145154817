import { createApp } from "vue"
import { createPinia } from "pinia"
import router from "@/src/router";
import App from '@/src/App.vue'
import axios from "axios";
import { useAuthStore } from "@/src/stores/auth";
import { SET_REDIRECT_TO } from "@/src/stores/mutation-types";

axios.defaults.headers.common["Accept"] = "application/json";

axios.interceptors.request.use(function(config) {
    const authStore = useAuthStore()
    config.headers["X-CSRF-Token"] = authStore.csrfToken;
    return config;
});


export default function() {
    router.beforeEach((to, from, next) => {
        const authStore = useAuthStore()
        if (to.matched.some((record) => record.meta.requiresAuth)) {
            if (authStore.isLoggedIn) {
                next();
            } else {
                authStore[SET_REDIRECT_TO](to)
                next({name: "login"});
            }
        } else {
            if (
                (to.name == "login" || to.name == "new_user") &&
                authStore.isLoggedIn
            ) {
                next({name: "dashboard"});
            } else {
                next();
            }
        }
    });
    router.onError(error => {
        let isChunkLoadError = /Loading[\s]?[\w]* chunk [^\s]+ failed/;
        if (isChunkLoadError.test(error.message)){
            console.error("Reloading after Chunk Load Error.", error);
            window.location.reload(true);
        }
        else {
            throw error;
        }
    });


    localStorage.removeItem("x-manifest-version-changed");
    document.addEventListener("DOMContentLoaded", () => {
        let versionInMetaTag = document.querySelector('meta[name=x-manifest-version]').content;
        if (versionInMetaTag){
            localStorage.setItem("x-manifest-version", versionInMetaTag);
        }
    })
    router.afterEach((to, from) => {
        let manifestChanged = localStorage.getItem("x-manifest-version-changed");
        if (manifestChanged){
            localStorage.removeItem("x-manifest-version-changed");
            window.location.reload(true);
        }
    })

    axios.interceptors.response.use(
        function(response) {
            // Do something with response data
            let newManifest = response.headers["x-manifest-version"];
            let currentManifest = localStorage.getItem("x-manifest-version");
            if (newManifest && currentManifest){
                if (currentManifest != newManifest){
                    localStorage.setItem("x-manifest-version", newManifest);           
                    localStorage.setItem("x-manifest-version-changed", true);
                }
            }
            else {
                if (newManifest){
                    localStorage.setItem("x-manifest-version", newManifest);
                }
            }
            return response;
        },
        function(error) {
            // Do something with response error
            if (error.response.status == 401) {
                const authStore = useAuthStore()
                authStore.logout({ router: router })
            }
            return Promise.reject(error);
        }
    );

    if (document.querySelectorAll('#app').length > 0){
        const pinia = createPinia()

        const app = createApp(App)
        app.use(router)
        app.use(pinia)
        app.mount("#app")
    }
}