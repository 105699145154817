<script>
export default {
    name: "App"
}
</script>

<template>
<div id="app">
      <router-view></router-view>
</div>    
</template>