import { defineStore } from 'pinia'
import axios from "axios"
import {LOGIN_START, LOGIN_SUCCESS, LOGIN_ERROR, LOGOUT_START, LOGOUT_SUCCESS, SET_REDIRECT_TO} from '@/src/stores/mutation-types'
import {UPDATE_USER} from '@/src/stores/mutation-types'
import {LOGIN, LOGOUT} from '@/src/stores/action-types'
import { useUserStore } from './user'

const LOGGED_IN_FLAG = "LOGGED_IN_FLAG"

export const useAuthStore = defineStore('auth', {
    state() {
        return {
            isLoggedIn: !!localStorage.getItem(LOGGED_IN_FLAG),
            loginPending: false,
            logoutPending: false,
            error: false,
            csrfToken: null,
            redirectTo: null
        }
    },
    actions: {
        [LOGIN_START](){
            this.loginPending = true;
            this.error = false;
        },
        [LOGIN_ERROR](){
            this.loginPending = false;
            this.error = true;
        },
        [LOGIN_SUCCESS](csrf_token){
            this.isLoggedIn = true;
            this.error = false;
            this.loginPending = false;
            this.csrfToken = csrf_token;
            localStorage.setItem(LOGGED_IN_FLAG, true)
            this.redirectTo = null
        },
        [LOGOUT_START](){
            this.logoutPending = true;
        },
        [LOGOUT_SUCCESS](){
            this.logoutPending = false;
            this.isLoggedIn = false;     
            localStorage.removeItem(LOGGED_IN_FLAG); 
            this.csrfToken = null    
            this.redirectTo = null 
        },
        [SET_REDIRECT_TO](redirectTo) {
            this.redirectTo = redirectTo
        },
        [LOGIN]({credentials, router, form}){
            form = form || {}
            this[LOGIN_START]()
            return new Promise(resolve => {
                axios.post("/sessions", credentials).then((response) => {
                    //TODO: mensagem de sucesso?
                    // if (response.data.notice){
                    //  this.$set(this.user, "notice", response.data.notice);
                    // }
                    // console.log("successful login", response.data)
                    const userStore = useUserStore()
                    userStore[UPDATE_USER](response.data.user)

                    let redirectTo = this.redirectTo
                    this[LOGIN_SUCCESS](response.data.csrf_token)

                    if (response.data.return_to) {
                        console.log("returning to ", response.data.return_to)                            
                        window.location = response.data.return_to;
                    }
                    else {
                        if (!redirectTo){
                            redirectTo = { name: "dashboard" }
                        }
                        console.log("redirecting to ", redirectTo) 
                        router.push(redirectTo);
                    }
                }).catch((error) => {
                    console.log("error---", error.response);
                    if (error.response.status == 400 && error.response.data.errors){
                        form.errors = error.response.data.errors
                    }              
                    this[LOGIN_ERROR]()
                }).then(() => resolve())
            });         
        },
        [LOGOUT]({router}){
            this[LOGOUT_START]()
            return new Promise(resolve => {
              axios.delete("/sessions/1").then((response) => {
                const userStore = useUserStore()
                userStore[UPDATE_USER]({
                    id: null,
                    name: null,
                    email: null,
                    time_zone: null
                })
                this[LOGOUT_SUCCESS]()
                router.push("/login")
              }).catch((error) => {
                this[LOGOUT_SUCCESS]()
                router.push("/login")
              }).then(() => resolve())
            }); 
        }
    }
})
