import { defineStore } from 'pinia'
import {UPDATE_USER} from '@/src/stores/mutation-types'

export const useUserStore = defineStore('user', {
    state() {
        return {
            user: {
                name: "",
                email: "",
                time_zone: "",
                password: "",
                old_password: "",
                showLoading: false
            }
        }
    },
    actions: {
        [UPDATE_USER](changedUser){
            this.user.name = changedUser.name
            this.user.email = changedUser.email
            this.user.time_zone = changedUser.time_zone
            this.user.password = changedUser.password
            this.user.old_password = changedUser.old_password
            this.user.showLoading = changedUser.showLoading
        }
    }
})
